import DmpIcon from "common/components/DmpIcon";
import { isNotNullOrUndefinedOrEmpty } from "common/utils/universal/function";
import LoadingSpinner from "components/LoadingSpinner";
import NakedButton from "components/NakedButton";
import {
  elevation,
  themeGreenValue,
  themeNotificationBlueBackground,
  themeNotificationBlueBorder,
  themeNotificationBlueText,
  themeRedValue,
} from "containers/Theme";
import { useAddBulkImportSnapshotToClear } from "hooks/use-app-notifications/use-app-notifications";
import useEffectOnce from "hooks/useEffectOnce";
import { useDispatch } from "hooks/useRedux";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { commitLocalUpdate, useRelayEnvironment } from "react-relay/hooks";
import { push } from "react-router-redux";
import { AppNotificationType } from "securecom-graphql/client";
import { requestAction } from "store/pendingConfirm/actions";
import styled, { css } from "styled-components";
import { seconds } from "utils/dates";
import messages from "./messages";
import ProgressCircle from "./ProgressCircle";
import { AppNotificationsQuery$data } from "./__generated__/AppNotificationsQuery.graphql";

type NotificationProps = {
  data: AppNotificationsQuery$data["appNotifications"][0];
};

const Notification = ({ data }: NotificationProps) => {
  const [isEntering, setIsEntering] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const relayEnv = useRelayEnvironment();
  const { id, type, message, action, entity, progress, autoDismiss, link } =
    data;
  const Container = CONTAINERS_BY_TYPE[type];
  const ActionButton = ACTION_BUTTONS_BY_TYPE[type];
  const ActionContainer = ACTION_CONTAINERS_BY_TYPE[type];
  const { formatMessage } = useIntl();
  const addBulkImportSnapshotToClear =
    useAddBulkImportSnapshotToClear(relayEnv);
  const reduxDispatch = useDispatch();
  const handleRedirect = (link: string) => {
    reduxDispatch(
      requestAction(() => {
        reduxDispatch(push(link));
      })
    );
    clearNotification();
  };

  useEffectOnce(() => setIsEntering(true));

  const displayMessage = message
    ? message
    : type === AppNotificationType.SUCCESS
    ? formatMessage(messages.success)
    : type === AppNotificationType.PROGRESS
    ? formatMessage(messages.progress)
    : type === AppNotificationType.ERROR
    ? formatMessage(messages.error)
    : formatMessage(messages.unknown);

  const clearNotification = () => {
    setIsExiting(true);
    setTimeout(() => {
      commitLocalUpdate(relayEnv, (store) => {
        const root = store.getRoot();
        const notifications = root.getLinkedRecords("appNotifications");

        const updatedNotifications = notifications?.filter(
          (n) => n.getValue("id") !== id
        );
        store.delete(id);
        root.setLinkedRecords(updatedNotifications, "appNotifications");
      });
    }, 200);
  };

  const clearBulkImportSnapshot = () => {
    addBulkImportSnapshotToClear(id);
  };

  const autoDismissable =
    ((autoDismiss === undefined || autoDismiss === null) &&
      (AppNotificationType.SUCCESS === type ||
        AppNotificationType.PROGRESS === type)) ||
    autoDismiss;

  if (autoDismissable) setTimeout(clearNotification, INITIAL_EXIT_DELAY);

  return (
    <AnimationWrapper isEntering={isEntering} isExiting={isExiting}>
      <Container>
        <MainContainer>
          <IconContainer>
            {type === AppNotificationType.ERROR ? (
              <DmpIcon
                icon="radial_warning"
                constrainToParent={false}
                size={32}
              />
            ) : null}
            {type === AppNotificationType.SUCCESS ? (
              <DmpIcon
                icon="radial_check"
                constrainToParent={false}
                size={32}
              />
            ) : null}
            {type === AppNotificationType.PROGRESS &&
            isNotNullOrUndefinedOrEmpty(progress) ? (
              <ProgressCircle progress={progress} />
            ) : null}
            {type === AppNotificationType.PROGRESS &&
            !isNotNullOrUndefinedOrEmpty(progress) ? (
              <StyledSpinner />
            ) : null}
          </IconContainer>
          <MessageContainer>
            {action ? <Action>{`${action} ${entity}`}</Action> : null}
            <Message>{displayMessage}</Message>
          </MessageContainer>
        </MainContainer>

        {link ? (
          <ActionContainer>
            <DismissButton
              onClick={() => {
                clearBulkImportSnapshot();
                clearNotification();
              }}
            >
              {formatMessage(messages.dismiss)}
            </DismissButton>
            <ActionButton onClick={() => handleRedirect(link)}>
              {formatMessage(messages.view)}
            </ActionButton>
          </ActionContainer>
        ) : null}

        {!autoDismissable && !link ? (
          <CloseButton onClick={clearNotification}>
            <DmpIcon icon="close_cancel" constrainToParent={false} size={10} />
          </CloseButton>
        ) : null}
      </Container>
    </AnimationWrapper>
  );
};

const INITIAL_EXIT_DELAY = seconds(5);

// This wrapper grows and shrinks when entering and exiting,
// dragging the notification with it.
const AnimationWrapper = styled.div<{
  isEntering: boolean;
  isExiting: boolean;
}>`
  transform-origin: bottom;
  transition: max-height 0.2s linear, opacity 0.3s linear;
  opacity: 0;
  max-height: 0;

  ${({ isEntering }) =>
    isEntering &&
    css`
      opacity: 1;
      max-height: 14rem;
    `};

  ${({ isExiting }) =>
    isExiting &&
    css`
      opacity: 0;
      max-height: 0;
    `};
`;

const BaseContainer = styled.div`
  position: relative;
  box-shadow: ${elevation(300)};
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 22rem;
  border: 0.125rem solid gray;
  border-radius: 0.625rem;
  min-height: 5rem;
`;

const MainContainer = styled.div`
  align-items: center;
  gap: 0.625rem;
  padding: 0.625rem;
  padding-right: 1.5rem;
  display: flex;
`;

const ActionContainer = styled.div`
  align-items: center;
  gap: 0.625rem;
  padding: 0.625rem;
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid;
`;

const ErrorActionContainer = styled(ActionContainer)`
  border-color: ${themeRedValue(200)};
`;

const SuccessActionContainer = styled(ActionContainer)`
  border-top-color: ${themeGreenValue(200)};
`;

const ActionButton = styled(NakedButton)`
  border-radius: 0.25rem;
  padding: 0.5rem 0.7rem;
  font-weight: 700;
`;

const DismissButton = styled(ActionButton)``;

const ProgressActionButton = styled(ActionButton)`
  background-color: ${themeNotificationBlueText};
  color: ${themeNotificationBlueBackground};
`;

const SuccessActionButton = styled(ActionButton)`
  background-color: ${themeGreenValue(900)};
  color: ${themeGreenValue(100)};
`;

const ErrorActionButton = styled(ActionButton)`
  background-color: ${themeRedValue(900)};
  color: ${themeRedValue(100)};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  width: 2rem;
`;

const StyledSpinner = styled(LoadingSpinner)`
  width: 2rem;
  height: 2rem;
`;

const Message = styled.p`
  display: flex;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  word-break: break-word;
`;

const Action = styled.p`
  display: flex;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
`;

const CloseButton = styled(NakedButton)`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
`;

const DefaultContainer = styled(BaseContainer)`
  background: white;
`;

const ErrorContainer = styled(BaseContainer)`
  background-color: ${themeRedValue(100)};
  border-color: ${themeRedValue(800)};
  color: ${themeRedValue(900)};
`;

const SuccessContainer = styled(BaseContainer)`
  background-color: ${themeGreenValue(100)};
  border-color: ${themeGreenValue(600)};
  color: ${themeGreenValue(900)};
`;

const ProgressContainer = styled(DefaultContainer)`
  background-color: ${themeNotificationBlueBackground};
  border-color: ${themeNotificationBlueBorder};
  color: ${themeNotificationBlueText};
`;

const CONTAINERS_BY_TYPE = {
  DEFAULT: DefaultContainer,
  ERROR: ErrorContainer,
  PROGRESS: ProgressContainer,
  SUCCESS: SuccessContainer,
};

const ACTION_CONTAINERS_BY_TYPE = {
  DEFAULT: ActionContainer,
  ERROR: ErrorActionContainer,
  PROGRESS: ActionContainer,
  SUCCESS: SuccessActionContainer,
};

const ACTION_BUTTONS_BY_TYPE = {
  ERROR: ErrorActionButton,
  SUCCESS: SuccessActionButton,
  PROGRESS: ProgressActionButton,
};

export default Notification;
