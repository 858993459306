import { ReactModalProps } from "common/utils/universal/types";
import React from "react";
import * as BaseReactModal from "react-modal";

/**
 * @description Creates a typed `react-modal` component. The library does not support typescript or export types as of version 3.11.
 * @param props Passthrough to react-modal. Type of `ReactModalProps`
 * @returns A typed `ReactModal`
 */
const ReactModal = (props: ReactModalProps) => {
  return <BaseReactModal {...props} />;
};

export default ReactModal;
