/**
 *
 * FieldWithSuggestions
 * @author Chad Watson & Seth MacPherson
 *
 */
import withClearButton from "components/Form/withClearButton";
import NakedButton from "components/NakedButton";
import NakedList from "components/NakedList";
import { BORDER_RADIUS } from "constants/index";
import {
  defaultThemeBoxShadow,
  themeGray,
  themeGrayLight,
  themeGrayXlight,
  themePanelBorder,
} from "containers/Theme";
import { search } from "hooks/useSearch";
import { List } from "immutable";
import keycode from "keycode";
import { omit } from "ramda";
import React, { useEffect, useReducer, useRef } from "react";
import Measure from "react-measure";
import styled, { css } from "styled-components/macro";
import { immutableToJS } from "utils";
import DefaultBigField, { INPUT_HEIGHT as BIG_FIELD_HEIGHT } from "./BigField";
import DefaultField, { HEIGHT_IN_PX as FIELD_HEIGHT } from "./Field";
const SUGGESTIONS_MAX_HEIGHT = 200;
const MEASURE_WHITELIST = ["bottom"];
const WRAPPER_ACTIVE_STYLE = {
  zIndex: 10,
};
const FIELD_WRAPPER_ACTIVE_STYLE = {
  zIndex: 2,
};
const SUGGESTIONS_ACTIVE_STYLE = {
  zIndex: 1,
};
const actionTypes = {
  hideSuggestions: "hide-suggestions",
  showSuggestions: "show-suggestions",
  highlightNextSuggestion: "highlight-next-suggestion",
  highlightPreviousSuggestion: "highlight-previous-suggestion",
  selectSuggestion: "select-suggestion",
  updateValueAndSuggestions: "update-value-and-suggestions",
  clearValueAndSuggestions: "clear-value-and-suggestions",
  blur: "blur",
  focus: "focus",
  clearPendingFocusReset: "clear-pending-focus-reset",
};

function fieldWithSuggestionsReducer(state, action) {
  switch (action.type) {
    case actionTypes.showSuggestions:
      return {
        ...state,
        suggestionsActive: true,
      };

    case actionTypes.hideSuggestions:
      return {
        ...state,
        suggestionsActive: false,
        highlightedSuggestionIndex: null,
      };

    case actionTypes.highlightNextSuggestion:
      return !state.suggestionsActive
        ? state
        : {
            ...state,
            highlightedSuggestionIndex:
              state.highlightedSuggestionIndex !== null
                ? Math.min(
                    state.highlightedSuggestionIndex + 1,
                    state.filteredSuggestions.count() - 1
                  )
                : 0,
          };

    case actionTypes.highlightPreviousSuggestion:
      return !state.suggestionsActive
        ? state
        : {
            ...state,
            highlightedSuggestionIndex:
              state.highlightedSuggestionIndex !== null
                ? Math.max(state.highlightedSuggestionIndex - 1, 0)
                : state.filteredSuggestions.count() - 1,
          };

    case actionTypes.selectSuggestion:
      return {
        ...state,
        hasFocus: false,
        suggestionsActive: false,
        highlightedSuggestionsIndex: null,
        filteredSuggestions: List(),
        value: immutableToJS(
          state.filteredSuggestions.get(action.payload.suggestion)
        ).item,
      };

    case actionTypes.updateValueAndSuggestions:
      return {
        ...state,
        value: action.payload.value,
        filteredSuggestions: action.payload.filteredSuggestions.toList(),
      };

    case actionTypes.clearValueAndSuggestions:
      return {
        ...state,
        value: "",
        pendingFocusReset: true,
        filteredSuggestions: List(),
      };

    case actionTypes.focus:
      return {
        ...state,
        hasFocus: true,
      };

    case actionTypes.blur:
      return {
        ...state,
        hasFocus: false,
        suggestionsActive: false,
        highlightedSuggestionIndex: null,
      };

    case actionTypes.clearPendingFocusReset:
      return {
        ...state,
        pendingFocusReset: false,
      };

    default:
      return state;
  }
}

const FieldWithSuggestions = (props) => {
  // Props Logic
  const FieldComponent = props.big ? BigField : Field; // References

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const suggestionRefs = useRef({
    current: [],
  }); // ---------------------------
  // State
  // ---------------------------

  const [
    {
      value,
      suggestionsActive,
      highlightedSuggestionIndex,
      hasFocus,
      pendingFocusReset,
      filteredSuggestions,
    },
    dispatch,
  ] = useReducer(fieldWithSuggestionsReducer, {
    value: props.value,
    suggestionsActive: false,
    highlightedSuggestionIndex: null,
    hasFocus: false,
    pendingFocusReset: false,
    filteredSuggestions: List(),
  });
  // ---------------------------
  // Event Handlers and Events
  // ---------------------------

  const hideSuggestions = () =>
    dispatch({
      type: actionTypes.hideSuggestions,
    });

  const highlightNextSuggestion = () =>
    dispatch({
      type: actionTypes.highlightNextSuggestion,
    });

  const highlightPreviousSuggestion = () =>
    dispatch({
      type: actionTypes.highlightPreviousSuggestion,
    });

  const selectSuggestion = (suggestion) =>
    dispatch({
      type: actionTypes.selectSuggestion,
      payload: {
        suggestion,
      },
    });

  const createKeyPressMapper = (keyPressMap) => (event) => {
    const keyHandler = keyPressMap[keycode(event.which)];

    if (keyHandler) {
      event.preventDefault();
      keyHandler();
    }
  };

  const wrapperKeyPressMap = {
    esc: hideSuggestions,
    down: highlightNextSuggestion,
    up: highlightPreviousSuggestion,
  };
  const handleWrapperKeyPress = createKeyPressMapper(wrapperKeyPressMap);

  const handleFieldValueChange = (event) => {
    const { value } = event.currentTarget;
    dispatch({
      type: actionTypes.updateValueAndSuggestions,
      payload: {
        value,
        filteredSuggestions:
          search(filteredSuggestions, props.suggestions, value) ?? List(),
      },
    });
  };

  const handleFieldFocus = () =>
    dispatch({
      type: actionTypes.focus,
    });

  const handleBlur = (event) => {
    const focusedElement = event.relatedTarget;
    const wrapper = wrapperRef.current;

    if (
      wrapper &&
      (wrapper === focusedElement || wrapper.contains(focusedElement))
    ) {
      return;
    }

    dispatch({
      type: actionTypes.blur,
    });
  };

  const handleClear = () =>
    dispatch({
      type: actionTypes.clearValueAndSuggestions,
    }); // ---------------------------
  // Effect
  // ---------------------------

  useEffect(
    () => {
      value !== "" &&
        hasFocus &&
        !filteredSuggestions.isEmpty() &&
        dispatch({
          type: actionTypes.showSuggestions,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasFocus, value]
  );
  useEffect(
    () => {
      props.value !== value && props.onChange(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );
  useEffect(
    () => {
      if (props.value !== value) {
        handleFieldValueChange({
          currentTarget: {
            value: props.value,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.value]
  );
  useEffect(() => {
    suggestionRefs.current[highlightedSuggestionIndex] &&
      suggestionRefs.current[highlightedSuggestionIndex].focus();
  }, [highlightedSuggestionIndex]);
  useEffect(() => {
    if (pendingFocusReset) {
      inputRef && inputRef.current && inputRef.current.focus();
      dispatch({
        type: actionTypes.clearPendingFocusReset,
      });
    }
  });

  return (
    <Measure whitelist={MEASURE_WHITELIST}>
      {({ bottom }) => (
        <div>
          <Wrapper
            style={suggestionsActive ? WRAPPER_ACTIVE_STYLE : undefined}
            onBlur={handleBlur}
            onKeyDown={handleWrapperKeyPress}
            ref={wrapperRef}
          >
            <FieldWrapper
              style={suggestionsActive ? FIELD_WRAPPER_ACTIVE_STYLE : undefined}
            >
              <FieldComponent
                {...omit(["big", "suggestions"], props)}
                inputRef={inputRef}
                value={value}
                onChange={handleFieldValueChange}
                onFocus={handleFieldFocus}
                onClear={handleClear}
                ancestorHasFocus={hasFocus}
                data-testid="field-with-suggestions-input"
              />
            </FieldWrapper>
            {suggestionsActive &&
              (filteredSuggestions.count() > 1 ||
                filteredSuggestions.first() !== value) && (
                <Suggestions
                  fieldHeight={props.big ? BIG_FIELD_HEIGHT : FIELD_HEIGHT}
                  positionAbove={
                    window.innerHeight - bottom < SUGGESTIONS_MAX_HEIGHT
                  }
                  style={
                    suggestionsActive ? SUGGESTIONS_ACTIVE_STYLE : undefined
                  }
                >
                  {filteredSuggestions.valueSeq().map((suggestion, index) => {
                    let suggestedItem;
                    if (typeof suggestion === "object") {
                      suggestedItem = immutableToJS(suggestion).item;
                    } else {
                      suggestedItem = suggestion;
                    }
                    return (
                      <li key={index.toString()}>
                        <Suggestion
                          value={suggestion}
                          onClick={() => selectSuggestion(index)}
                          ref={(ref) => {
                            suggestionRefs.current[index] = ref;
                          }}
                          data-testid="field-with-suggestions-suggestion"
                        >
                          {suggestedItem}
                        </Suggestion>
                      </li>
                    );
                  })}
                </Suggestions>
              )}
          </Wrapper>
        </div>
      )}
    </Measure>
  );
};

FieldWithSuggestions.defaultProps = {
  value: "",
  big: false,
};
/**
 * @deprecated Prefer `SelectSearchable` or `SelectCreatable` instead
 */
export default FieldWithSuggestions;
const Wrapper = styled.div`
  position: relative;
`;
const BigField = styled(withClearButton(DefaultBigField))`
  outline: none;
`;
const Field = styled(withClearButton(DefaultField))`
  outline: none;
`;
const FieldWrapper = styled.div`
  position: relative;
`;
const Suggestions = styled(NakedList)`
  position: absolute;
  left: 0;
  right: 0;
  max-height: ${SUGGESTIONS_MAX_HEIGHT}px;
  background: white;
  border: ${themePanelBorder};
  border-radius: ${BORDER_RADIUS};
  box-shadow: ${defaultThemeBoxShadow};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${({ positionAbove, fieldHeight }) =>
    positionAbove
      ? css`
          bottom: 0;
          padding-bottom: ${fieldHeight}px;
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : css`
          top: 0;
          padding-top: ${fieldHeight}px;
          border-top: none;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        `};
`;
const Suggestion = styled(NakedButton)`
  background-color: transparent;
  width: 100%;
  padding: 0.5em 1em;
  color: ${themeGray};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
  text-align: inherit;

  &:hover,
  &:focus {
    background: ${themeGrayXlight};
  }

  &:active {
    background: ${themeGrayLight};
  }
`;
